import React, { Component } from "react";
import axios from "axios";
import "./Search.css";
import Place from "./Place";

export class Search extends Component {
  state = {
    query: "Places",
    results: [],
    status: 3,
    resultss: [
      { title: "Tesla", id: 2, name: "kuda", city: "harare" },
      { title: "Honda", id: 9, name: "bryce", city: "bulawayo" }
    ],
    filteredplaces: []
  };

  getInfo = () => {
    axios
      .get(
        `https://q-manity.solarce.de/api?api_key=5sYMosToRnTjCnrep9vU&model=${
          this.state.query
        }`
      )
      .then(({ data }) => {
        this.setState({
          results: data.data.Places,
          filteredplaces: data.data.Places
        });
      });
  };
  componentWillMount() {
    this.getInfo();
  }
  componentDidMount() {
    this.setState({
      status: this.props.location.state
    });
  }
  handleInputChange = () => {
    // this.getInfo();
    let filteredplaces = this.state.results;
    filteredplaces = filteredplaces.filter(place => {
      let placeName = place.name.toLowerCase() + place.city.toLowerCase();
      return placeName.indexOf(this.search.value.toLowerCase()) !== -1;
    });
    console.log(filteredplaces);
    this.setState({
      filteredplaces
    });
  };

  render() {
    return (
      <div className="container mt-4">
        <br />
        <br />
        <form>
          <input
            placeholder="Search by Name, City... "
            ref={input => (this.search = input)}
            onChange={this.handleInputChange}
            className="form-control mb-3"
          />
          <Place
            results={{
              places: this.state.filteredplaces,
              userId: 90
            }}
          />
        </form>
      </div>
    );
  }
}

export default Search;
