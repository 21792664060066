import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";

export class Workers extends Component {
  state = {
    results: []
  };
  componentDidMount() {
    this.getInfo(this.props.match.params.placeId);
  }

  getInfo = () => {
    axios
      .get(
        `https://q-manity.solarce.de/api?api_key=5sYMosToRnTjCnrep9vU&model=Users`
      )
      .then(({ data }) => {
        this.setState({
          results: data.data.Users
        });
      });
  };

  render() {
    const users = this.state.results.map(r => (
      <div className="col-md-4 mb-2" key={r.id}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-lg-8 col-md-6">
                <h4 className="mb-0 text-truncated">
                  {r.first_name[0] + " " + r.last_name}
                </h4>
                <p className="lead">
                  {r.city} {r.state}
                </p>
              </div>
              <div className="col-12 col-lg-4 col-md-6 text-center">
                <img
                  src={r.profile_pic_url}
                  alt=""
                  className="mx-auto rounded-circle img-fluid"
                />
              </div>
              <div className="col-12 col-lg-6">
                <h5 className="mb-0">5</h5>
                <small>Ratings</small>
                <Link
                  to={`/booking/${r.id}`}
                  className="btn btn-block btn-xs btn-outline-success my-btn-outline"
                >
                  Book
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
    return (
      <div className="container">
        <div className="row">{users}</div>
      </div>
    );
  }
}

// const mapStateToProps = state => ({
//   Workers: state.Workers.Workers
// });

export default withRouter(Workers);
