import React, { Component } from "react";

export class Payment extends Component {
  state = {
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    password2: ""
  };

  render() {
    const { username, email, first_name } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="alert alert-warning" role="alert">
              No Credit card linked
            </div>
          </div>
          <div className="col-md-7 m-auto">
            <div className="card card-body mt-7">
              <h2 className="text-center">Credit Card Information</h2>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label>Name on Card</label>
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    onChange={this.onChange}
                    value={username}
                  />
                </div>
                <div className="form-group">
                  <label>Card Number</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    onChange={this.onChange}
                    value={email}
                  />
                </div>
                <div className="form-group">
                  <label>CVV Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    onChange={this.onChange}
                    value={first_name}
                  />
                </div>
                <div className="form-group row">
                  <label className="col-md-12">Card Exp. Date</label>
                  <div className="col-md-4">
                    <select className="form-control" name="cc_exp_mo" size="0">
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <select className="form-control" name="cc_exp_yr" size="0">
                      <option>2018</option>
                      <option>2019</option>
                      <option>2020</option>
                      <option>2021</option>
                      <option>2022</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary">
                    Add Card
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Payment;
