import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Profile from "./components/account/Profile";
import Search from "./components/search/Search";
import Layout from "./components/Layout";
import { Workers } from "./components/search/Workers";
import Payment from "./components/account/Payment";
import Booking from "./components/Booking";

function App() {
  return (
    <Router>
      <Layout />
      <Switch>
        <Route exact path="/" component={Search} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/payment" component={Payment} />
        <Route exact path="/booking/:placeId" component={Booking} />
      </Switch>
    </Router>
  );
}

export default App;
