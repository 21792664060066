import React, { Component } from "react";
import axios from "axios";

export class Booking extends Component {
  state = {
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    password2: ""
  };

  onSubmit = e => {
    e.preventDefault();

    const body = JSON.stringify({
      // needed_when: this.state.when_needed,
      place_id: this.props.match.params.placeId,
      facebook_uid: this.props.location.state.userId,
      service_id: 1
    });

    axios
      .post(
        "https://q-manity.solarce.de/api?api_key=5sYMosToRnTjCnrep9vU&model=Requests",
        body,
        { header: { "Access-Control-Allow-Origin": "*" } }
      )
      .then(res => {
        console.log(res);
        if (res.data.status === "ok") {
          this.props.history.push({ pathname: "/", state: { status: 1 } });
        } else {
          this.props.history.push({ pathname: "/", state: { status: 0 } });
        }
      });
  };

  componentDidMount() {
    console.log(this.props.location.state);
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-7 m-auto">
            <div className="card card-body mt-5">
              <h2 className="text-center">Confirm booking</h2>
              <form onSubmit={this.onSubmit}>
                <div className="form-group text-center">
                  <button type="submit" className="btn btn-primary">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Booking;
