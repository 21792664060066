import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Layout extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-md dark-primary-color fixed-top mb-5">
        <Link to="/" className="nav-link text-primary-color">
          Q-manity
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExampleDefault"
          aria-controls="navbarsExampleDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarsExampleDefault">
          <ul className="navbar-nav mr-auto">
            <li className="nav-link text-primary-color">
              <Link to="/" className="nav-link text-primary-color">
                Search
              </Link>
            </li>
            <li className="nav-link text-primary-color">
              <Link to="/profile" className="nav-link text-primary-color">
                Profile
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Layout;
