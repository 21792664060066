import React from "react";
import { Link } from "react-router-dom";

const Place = props => {
  const options = props.results.places.map(r => (
    <Link
      to={{pathname: `/booking/${r.id}`,state: {userId: props.results.userId}}}
      className="list-group-item secondary-text-color"
      key={r.id}
    >
      {r.name}
      <div className="pull-right mr-0 secondary-text-color">
        <span className="badge badge-pill badge-dark">{r.country_iso_alpha2}</span>
      </div>
    </Link>
  ));
  return (
  <div className="mt-8">
    <ul className="list-group">{options}</ul>
  </div>);
};

export default Place;
